import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom"
import Chart from "react-apexcharts";
import { Modal } from 'react-responsive-modal';
import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress, whiteListNFT } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dngrsdragon from "../../static/images/dngrs-dragon.png";
import scratch from "../../static/images/scratch.png";
import connectorslide from "../../static/images/connectorslide.png";



const Whitelist = (props) => {

    let [whitelistModal, setWhitelistModal] = useState(false);
    let [whitelistName, setWhitelistName] = useState("");
    let [whitelistEmail, setWhitelistEmail] = useState("");
    let [whitelistPhone, setWhitelistPhone] = useState("");
    let [discordUsername, setDiscordUsername] = useState("");
    let [twitter, settwitter] = useState("");
    let [whiteListeAddres, setWhiteListAddress] = useState("");


    const submitWhitelistModel = async () => {
        try {

            if (!whitelistName && !whitelistName.trim()) return EventBus.publish('error', `Please enter name`);
            // if (!whitelistEmail && !whitelistEmail.trim()) return EventBus.publish('error', `Please enter valid email`);
            // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const regex2 = /^0\.0\.\d+$/;
            // if (!regex.test(whitelistEmail)) return EventBus.publish('error', `Please enter valid email`);
            // if (!whitelistPhone && !whitelistPhone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!regex2.test(whiteListeAddres)) return EventBus.publish('error', `Please enter valid hashpack wallet address`);

            props.whiteListNFT({ publicAddress: whiteListeAddres, name: whitelistName, email: whitelistEmail, phone: whitelistPhone, discordUsername, twitter });
            setWhitelistModal(false)

            props.toggleLoader({
                message: "Whitelisting in Progress...",
                status: true,
            });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    return (
        <div>
            <Navbar />

            <section className="explore vh">
                <div className="container">
                    <div className="head text-center">
                        <h1>Whitelist For Partners NFT</h1>
                    </div>

                    <div>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Name"
                                onChange={e => setWhitelistName(e.target.value)}
                                defaultValue={whitelistName}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="email"
                                placeholder="Email"
                                onChange={e => setWhitelistEmail(e.target.value)}
                                defaultValue={whitelistEmail}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="tel"
                                placeholder="Phone"
                                onChange={e => setWhitelistPhone(e.target.value)}
                                defaultValue={whitelistPhone}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="X Username"
                                onChange={e => settwitter(e.target.value)}
                                defaultValue={twitter}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Discord Username"
                                onChange={e => setDiscordUsername(e.target.value)}
                                defaultValue={discordUsername}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Hashpack Wallet Address"
                                onChange={e => setWhiteListAddress(e.target.value)}
                                defaultValue={whiteListeAddres}
                            />
                        </div>

                        <button className="common-btn pr" onClick={submitWhitelistModel} > Submit Now <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress,
    whiteListNFT
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(Whitelist);